/* eslint-disable @typescript-eslint/naming-convention */

import { DSColorDigits } from 'shared-definitions/types'

export function disqusIdentifier(postId: string, appUrl?: string): string {
  return `${postId} ${appUrl ?? ''}/?p=${postId}`
}

export function isServerSide(): boolean {
  return typeof window === 'undefined'
}

export function parseCookieString(cookie: string): Record<string, string> {
  return Object.fromEntries(cookie.split('; ').map(x => x.split('='))) as Record<string, string>
}

export function isInIframe(): boolean {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export function makeConfig<T extends Record<string, unknown>>(
  config: T
): <K extends keyof T>(key: K) => T[K] {
  return key => config[key]
}

export function uniqueIdString(): string {
  return `id${Math.ceil(Math.random() * 100000000)}`
}

export function parseColor(color: string): DSColorDigits | null {
  const colors = /#([\dabcdf]{2})([\dabcdf]{2})([\dabcdf]{2})/.exec(color)
  return colors ? [parseInt(colors[1], 16), parseInt(colors[2], 16), parseInt(colors[3], 16)] : null
}

// export function uuidv4(): string {
//   return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c => {
//     const n = parseInt(c, 10)
//     return (n ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (n / 4)))).toString(16)
//   })
// }
