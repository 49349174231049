import { BasicStyledComponent } from 'shared-definitions/types'

const TakeoverScript: React.VFC<Required<BasicStyledComponent>> = ({ className }) => (
  <script
    dangerouslySetInnerHTML={{
      __html: `function addTakeover() { console.log('addTakeover called'); var el = document.querySelector('body'); if (el) { el.classList.add('${className}') } }`,
    }}
  />
)

export default TakeoverScript
