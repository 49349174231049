import type { GeolocationReport } from 'shared-code/geo'

export async function locateCurrentIp(): Promise<GeolocationReport> {
  const locateRes = await fetch('/api/locate/')
  const parsed = (await locateRes.json()) as { result: null | GeolocationReport }

  if (parsed.result) {
    return parsed.result
  }
  return { countryCode: 'US' }
}
