import { DSDataLayer } from 'shared-definitions/types'
import { sessionCache } from 'shared-code/cache'
import { locateCurrentIp } from 'shared-code/geo-client'
import { logNotice } from 'shared-code/log'

export async function gtmDatalayer(dataLayer: DSDataLayer | null): Promise<void> {
  if (!dataLayer) {
    return
  }

  const geoResult = await sessionCache('geoCountry', async () => {
    logNotice('locate request')
    const geo = await locateCurrentIp()
    return JSON.stringify(geo)
  })
  const geoParsed = JSON.parse(geoResult) as Awaited<ReturnType<typeof locateCurrentIp>>

  // console.log('dataLayer', dataLayer)
  return new Promise(r => {
    function handler(): void {
      if (window.dataLayer) {
        // console.log('dataLayer 2000', dataLayer)
        window.dataLayer.push({
          ...dataLayer,
          country: geoParsed.countryCode,
        })
        r()
      } else {
        setTimeout(handler, 10)
      }
    }

    setTimeout(handler, 10)
  })
}

// const changeVault: Record<string, any> = {}
// export async function pushGtmValueChange<T>(
//   key: string,
//   value: T,
//   ifChanged: (val: T) => DSDataLayer
// ): Promise<void> {
//   if (changeVault[key] !== value) {
//     changeVault[key] = value
//     await gtmDatalayer(ifChanged(value))
//   }
// }
