import { useReportWebVitals } from 'next/web-vitals'
import { FirstInputPolyfillEntry, Metric, NavigationTimingPolyfillEntry } from 'web-vitals'

type MetricType = Metric & { target: string }

// delta
// name
// id
// rating
// target
// value

export function useAttributedWebVitals(fn: (metric: MetricType) => void): void {
  useReportWebVitals(m => {
    const rawMetric = m as Metric

    fn({ ...rawMetric, target: attributeTarget(rawMetric) })
  })
}

function attributeTarget(metric: Metric): string {
  let target = ''

  if (metric.name === 'CLS') {
    if (metric.entries.length) {
      const largestEntry = getLargestLayoutShiftEntry(metric.entries as LayoutShift[])
      if (largestEntry && largestEntry.sources && largestEntry.sources.length) {
        const largestSource = getLargestLayoutShiftSource(largestEntry.sources)
        if (largestSource) {
          target = getSelector(largestSource.node)
        }
      }
    }
  } else if (metric.name === 'FID') {
    const fidEntry = metric.entries[0] as FirstInputPolyfillEntry | PerformanceEventTiming
    target = getSelector(fidEntry.target)
  } else if (metric.name === 'LCP') {
    if (metric.entries.length) {
      const navigationEntry = getNavigationEntry()

      if (navigationEntry) {
        const { responseStart } = navigationEntry
        if (!isInvalidTimestamp(responseStart)) {
          const lcpEntry = metric.entries[metric.entries.length - 1] as LargestContentfulPaint
          target = getSelector(lcpEntry.element)
        }
      }
    }
  } else if (metric.name === 'INP') {
    const firstEntryWithTarget = (metric.entries as PerformanceEventTiming[]).find(
      entry => entry.target
    )

    target = getSelector(firstEntryWithTarget && firstEntryWithTarget.target)
  }

  return target
}

export function isInvalidTimestamp(timestamp: DOMHighResTimeStamp): boolean {
  return timestamp <= 0 || timestamp > performance.now()
}

function getLargestLayoutShiftEntry(entries: LayoutShift[]): LayoutShift {
  return entries.reduce((a, b) => (a && a.value > b.value ? a : b))
}

function getLargestLayoutShiftSource(sources: LayoutShiftAttribution[]): LayoutShiftAttribution {
  return sources.find(s => s.node && s.node.nodeType === 1) || sources[0]
}

function getName(node: Node): string {
  const name = node.nodeName
  return node.nodeType === 1 ? name.toLowerCase() : name.toUpperCase().replace(/^#/, '')
}

function getSelector(node: Node | null | undefined, maxLen?: number): string {
  let sel = ''

  try {
    while (node && node.nodeType !== 9) {
      const el: Element = node as Element
      const part = el.id
        ? '#' + el.id
        : getName(el) +
          (el.classList &&
          el.classList.value &&
          el.classList.value.trim() &&
          el.classList.value.trim().length
            ? '.' + el.classList.value.trim().replace(/\s+/g, '.')
            : '')
      if (sel.length + part.length > (maxLen || 100) - 1) {
        return sel || part
      }
      sel = sel ? part + '>' + sel : part
      if (el.id) {
        break
      }
      node = el.parentNode
    }
  } catch (err) {
    // Do nothing...
  }
  return sel
}

function getNavigationEntry():
  | PerformanceNavigationTiming
  | NavigationTimingPolyfillEntry
  | undefined {
  if (window.__WEB_VITALS_POLYFILL__) {
    return (
      window.performance &&
      ((performance.getEntriesByType && performance.getEntriesByType('navigation')[0]) ||
        getNavigationEntryFromPerformanceTiming())
    )
  }
  return (
    window.performance &&
    performance.getEntriesByType &&
    performance.getEntriesByType('navigation')[0]
  )
}

function getNavigationEntryFromPerformanceTiming(): NavigationTimingPolyfillEntry {
  const timing = performance.timing
  const type = performance.navigation.type

  const navigationEntry: { [key: string]: number | string } = {
    entryType: 'navigation',
    startTime: 0,
    // eslint-disable-next-line no-nested-ternary, eqeqeq
    type: type == 2 ? 'back_forward' : type === 1 ? 'reload' : 'navigate',
  }

  for (const key in timing) {
    if (key !== 'navigationStart' && key !== 'toJSON') {
      navigationEntry[key] = Math.max(
        (timing[key as keyof PerformanceTiming] as number) - timing.navigationStart,
        0
      )
    }
  }
  return navigationEntry as unknown as NavigationTimingPolyfillEntry
}
