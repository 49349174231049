import type { AppProps } from 'next/app'
import Head from 'next/head'
import { gtmDatalayer } from 'shared-code/gtm'
import TakeoverScript from 'shared-components/ad/TakeoverScript'
import { useAttributedWebVitals } from 'shared-components/hooks/use-attributed-web-vitals'
import 'shared-components/styles/globals.css'
import 'shared-components/styles/preflight.css'
import stylesUtils from 'shared-components/styles/util.module.css'

const AndroidAuthorityApp: React.VFC<AppProps> = ({ Component, pageProps }) => {
  // references
  // https://github.com/google-marketing-solutions/web-vitals-gtm-template
  // https://github.com/GoogleChrome/web-vitals?tab=readme-ov-file#report-only-the-delta-of-changes
  useAttributedWebVitals(metric => {
    void gtmDatalayer({
      event: 'web_vitals',
      webVitalsData: { ...metric, entries: null },
    })
  })

  return (
    <>
      <Head>
        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="/pwa/apple-touch-icon.png" />
        <meta name="theme-color" content="#00d195" />
        <TakeoverScript className={stylesUtils.takeoverOn} />
      </Head>
      <Component {...pageProps} />
    </>
  )
}

export default AndroidAuthorityApp

// export function reportWebVitals({ id, label, value, name }: NextWebVitalsMetric): void {
//   void gtmDatalayer({
//     event: 'web-vitals',
//     event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
//     event_action: name,
//     // Google Analytics metrics must be integers, so the value is rounded.
//     // For CLS the value is first multiplied by 1000 for greater precision
//     // (note: increase the multiplier for greater precision if needed).
//     event_value: Math.round(name === 'CLS' ? value * 1000 : value),
//     // The 'id' value will be unique to the current page load. When sending
//     // multiple values from the same page (e.g. for CLS), Google Analytics can
//     // compute a total by grouping on this ID (note: requires `eventLabel` to
//     // be a dimension in your report).
//     event_label: id,
//     non_interaction: true, // avoids affecting bounce rate.
//   })
// }
