/* eslint-disable no-console */
export function logError(...args: any[]): void {
  console.error(args)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function logNotice(...args: any[]): void {
  console.log(args)
}

export function logWarning(...args: any[]): void {
  console.warn(args)
}
